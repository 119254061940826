import Header from "../components/Header/Header";
import MainInfo from "../components/MainInfo/MainInfo";

const Inicio = (props) => {

  const {setPage, page} = props;

  return (
    <>
    <Header setPage={setPage} page={page} />
    <MainInfo/>
    </>
  )
}

export default Inicio